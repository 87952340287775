.applications-grid {
  margin-top: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 992px) {
  .applications-grid {
    margin-right: 10px;
    margin-top: 5px;
  }
}
