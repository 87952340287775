.quote-builder-container {
  height: 72.5%;
  border-radius: 0px 0px 0px 5px;
  background: #0a1045;
  padding: 20px 15px 25px 35px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  min-height: 390px;

  .quote-builder-header {
    padding-right: 20px;
  }

  .form-control-sm {
    font-weight: 700;
    color: #0a1045;
    height: 30px;
  }
  .cancel-px {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 0.5px 10px;
    border: 1px solid #bdbdbd;
    color: #fff;
    font-size: 10px !important;
    font-weight: 400;

    @media (max-width: 768px) {
      right: 25px;
    }
  }

  h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding-right: 20px;

    &.subtitle {
      color: #fffdfd;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      padding-right: 20px;
    }
  }

  .info-text {
    color: #d6d6d6;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 15px;

    &.larger {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      padding-right: 20px;

      &.recalc-msg {
        padding-right: 0;
      }
    }
  }
  .form-select-sm {
    font-weight: 700;
    color: #0a1045;
    height: 30px;
  }
  .quote-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
    padding-right: 20px;
    margin-bottom: 10px;

    .quote-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;

      .quote-info-label {
        color: #f8f8f8;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }

      .quote-info-field {
        width: 50%;
        text-align: right;

        &.full {
          max-width: 100%;
          width: 100%;
        }

        &.form-item {
          margin-bottom: 10px;
        }

        &.vrm-input {
          border-radius: 5px;
          background: #d6cd43;
          display: flex;
          padding: 0 10px 0 20px;
          align-items: center;

          input {
            background: transparent;
            border: none;
            outline: none;
            text-align: center;
            font-size: 26px;
            line-height: 24px;
            text-transform: uppercase;
            font-weight: 700;
            color: #000;
            -webkit-box-shadow: 0 0 0px 1000px #d6cd43 inset;

            &:focus {
              box-shadow: none;
            }
          }

          button {
            margin-top: 0;
            border: 2px solid #0a1045;
            border-radius: 2px;
            color: #0a1045;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
          }
        }

        .service-history-options {
          gap: 20px;

          .button-option {
            color: #404aa5;
            border-color: #404aa5;
            padding: 4px 5px;
            min-height: initial;
            font-size: 16px;
            flex-basis: auto;
            width: 73px;

            &.selected {
              background: transparent;
              color: #fff;
              border-color: #88fb00;
            }
            &:hover {
              border-color: #88fb00;
              color: #fff;
            }
          }
        }

        .service-history-info {
          path {
            fill: #fff;
          }
        }

        input,
        select {
          text-align: left;
          width: 100%;
          font-size: 16px;

          &.text-left {
            text-align: left;
          }

          &.text-center {
            text-align: center;
          }
        }

        button {
          border: 1px solid #404aa5;
          border-radius: 3px;
          padding: 0px 10px;
          color: #fff;
          font-weight: 700;

          &:hover {
            border-color: #88fb00;
            color: #fff;
          }

          &.yes-button {
            background: transparent;
            color: #fff;
            border-color: #88fb00;
            font-size: 16px;
          }
        }

        &.part-exchange-container {
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          border-radius: 4px;
          background: #fff;

          & > div {
            border-radius: 3px;
            border: 1px solid #404aa5;
            background: #d9d9d9;
            color: #0a1045;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          & > input {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
      }
    }

    .quote-px-info {
      width: 100%;

      p {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }

      .vehicle-vrm {
        margin-top: 10px;
        display: inline-block;
        padding: 5px;
        border-radius: 2px;
        background: #d6cd43;
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.5px;
      }
    }

    .px-error {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 10px;
      text-align: center;
    }

    .total-deposit {
      color: #88fb00;
      text-align: right;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .quote-actions {
    margin-top: auto;
    padding-right: 20px;
  }
  .quote-actions-vrm {
    margin-top: auto;
  }

  @media screen and (min-width: 1600px) {
    height: 70%;
  }

  @media screen and (max-width: 768px) {
    .px-form {
      .quote-actions {
        padding-right: 0;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    &.mobile {
      display: none;
    }
  }

  .yes-button {
    font-size: 14px;
    font-weight: 600;
    &:hover {
      border: 1px solid #88fb00;
    }
  }
}
