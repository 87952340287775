.retailers-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
}

.retailer-card {
  border-radius: 2px;
  background: #fff;
  padding: 20px;
  margin: 10px;
  width: calc(25% - 20px); /* Adjust width to fit 4 cards in a row */
  height: 350px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  box-shadow: 0px 3.573px 17.867px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 150px;
    object-fit: contain;
    margin: auto;
  }
}
.retailer-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.retailer-details .offers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: 700;
    color: var(--blue-title);
    font-size: 32px;
  }
}
.retailer-details .vehicle-details {
  font-size: 18px;
  h5 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-gray);
  }
}

.retailer-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.retailer-details p {
  margin: 0px 0;
  font-weight: 600;
  color: var(--blue-title);
}

.view-offers-button {
  background-color: var(--main-blue);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 20px;

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.view-offers-button:hover {
  background-color: #101969;
}
.cant-find-retailer {
  font-size: 20px;
  font-weight: 700;
  color: var(--main-blue);
  margin-top: 100px;
}
.offers-title-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.archive-button {
  border: 2px solid #cecece;
  border-radius: 4px;
  font-size: 18px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .archive-button {
    border: 2px solid #cecece;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
  }
  .retailers-grid {
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
    overflow-y: auto;
    flex-wrap: nowrap;
    gap: 20px;
    margin-top: 10px;

    .retailer-card {
      margin: 0;
      width: 100%;
    }
  }

  .page-retailers,
  .page-myoffers,
  .page-retailers-offers {
    .dash-content {
      padding-bottom: 0;
    }
  }

  .retailer-card {
    width: calc(100% - 25px);
  }
  .offers-title-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
  }
}
