.product-info-modal {
  .modal-dialog {
    max-width: 100%;
    width: 800px;

    .modal-header {
      border-bottom: 0;
      padding: 20px 30px;
    }

    .modal-body {
      padding: 20px 30px;
      color: #121a66;
      letter-spacing: -0.5px;
      font-weight: 400;

      .info-title {
        font-size: 20px;
      }
    }
  }
}

.modal {
  &.blurred {
    .modal-dialog {
      max-width: 600px;

      @media (max-width: 600px) {
        max-width: 95% !important;
      }

      .modal-content {
        background: rgba(10, 16, 69, 0.75);
        backdrop-filter: blur(2px);
        padding: 35px 35px 25px;

        .modal-header {
          background: #fff;
          border-bottom: 0;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          color: #121a66;
          font-weight: 600;
          letter-spacing: -0.5px;

          .lender-logo {
            height: 30px;
            width: auto;
            margin-left: 20px;
          }
        }

        .modal-body {
          background: #fff;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        .modal-footer {
          border-top: 0;
          padding: 10px 0 0;

          button {
            margin-bottom: 0;
            padding: 7.5px 20px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.commission-statement-modal {
    .modal-dialog {
      max-width: 800px;
    }

    .modal-header {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0;

      svg {
        width: 150px;
      }
    }

    .modal-body {
      padding: 20px 50px;

      ol {
        margin-top: 20px;
        list-style-type: decimal;
        list-style-position: inside;

        li {
          margin-bottom: 10px;
          font-size: 14px;

          b {
            padding-left: 10px;
          }

          p {
            margin-top: 5px;
          }
        }
      }
    }
  }

  &.commission-modal {
    .modal-header {
      padding-bottom: 0;
    }

    .modal-body {
      button {
        margin-top: 30px;
      }
    }
  }

  &.static {
    &.show {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(14, 14, 14, 0.58);
      backdrop-filter: blur(2px);

      .modal-dialog {
        width: 80%;

        .modal-content {
          padding: 20px 20px 0;
          border-radius: 5px;
          border: 2px solid #88fb00;
          background: #121a66;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          font-size: 16px;
          font-weight: 600;
        }

        .modal-footer {
          border-top: 0;
        }
      }
    }
  }

  &.large {
    .modal-dialog {
      max-width: 800px;
    }
  }
}

.confirm-modal {
  .modal-footer {
    button {
      margin: 0 5px;

      &.grey {
        padding: 7px 35px;
        border-radius: 3px;
      }
    }
  }
}
