button {
  border: 0;
  font-weight: 600;
  margin-top: 10px;
  font-family: "Stage Grotesk";
  color: var(--blue-title);
  font-size: 16px;
  padding: 10px 30px;

  &.dark-blue {
    background: #0a1045;
    color: #fff;
    border-radius: 5px;
  }

  &.rounded {
    border-radius: 3px;
  }

  &.dark {
    border-radius: 3px;
    background: #514d4d;
    color: #fff;
    font-weight: 700;
    margin-left: 0;
    padding: 7px 30px;
  }

  &.light-blue {
    background: #e8fafc;
  }

  &.green {
    border-radius: 3px;
    background: #80ea02;
    color: #0a1045;
    text-align: center;
    font-size: 13px !important;
    font-weight: 700;
    width: 100%;
    padding: 8px 30px;
  }
  &.green-2 {
    border-radius: 3px;
    background: #80ea02;
    color: #0a1045;
    text-align: center;
    font-size: 16px !important;
    font-weight: 700;
    width: 100%;
    padding: 8px 30px;
  }

  &.grey {
    background-color: #d9d9d9;
    padding: 9px 20px;
    color: #000;
    margin-top: 0;
  }

  &.with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.5px 30px;

    svg {
      margin-left: 20px;
    }
  }

  &.full {
    width: 100%;
  }

  &.outline {
    color: #b9b6b6;
    border: 1px;
    border-radius: 3px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #0a1044;
    }

    svg,
    img {
      margin-right: 15px;
    }
  }

  &.secondary {
    border-radius: 4px;
    border: 2px solid #cecece;
    color: #0a1045;
    font-size: 18px;
    font-weight: 700;
    padding: 4px 20px;
  }

  &.undo-button {
    border-radius: 3px;
    background: #252f89;
    color: #fff;
    text-align: center;
    font-size: 10px !important;
    font-weight: 700;
    padding: 6px 20px;
    margin-top: 0;
  }
}

.buttons-list {
  button {
    margin-bottom: 20px;
    height: 45px;
  }
}
