.dashboard-content {
  display: flex;
  height: 100%;
  max-width: 70dvw;
  overflow: hidden;

  .left-hand-content {
    flex: 0 0 50%;
    background: #f9f9f9;
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 120px 80px;

    .content-logo {
      margin-bottom: 0px;
      width: 200px;
    }

    @media (min-width: 1500px) and (max-width: 1700px) {
      padding: 30px 60px 30px;

      .logo-container {
        margin-bottom: 20px;

        .content-logo {
          margin-bottom: 0;
        }
      }

      .feature {
        margin-bottom: 30px;
      }
    }
  }

  .right-hand-content {
    flex: 0 0 50%;
    background-image: url("../../images/dashboard.png");
    background-color: #fff;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    .left-hand-content {
      flex: 0 0 100%;
      order: 1;
      background: transparent;
      padding: 20px 20px 20dvh 80px;
      overflow-y: auto;

      .logo-container {
        padding-right: 60px;
      }
    }

    .right-hand-content {
      flex: 0 0 100%;
      order: 0;
      max-height: 20dvh;
      background-position: 50% 25%;
    }
  }
  @media screen and (max-width: 767px) {
    .right-hand-content {
      background-color: #fff;
      background-position: center;
      background-size: 50%;
      border-radius: 5px;
      background-repeat: no-repeat;
      margin-top: 20px;
      max-height: 30dvh;
    }
    .logo-container {
      display: none;
    }
    .left-hand-content {
      justify-content: flex-start;
      margin-top: 25px;
    }
  }
}
