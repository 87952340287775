body {
  overflow: hidden;
}

.guest-layout {
  background: var(--main-bg-color);
  min-height: 100dvh;

  .page-logo {
    width: 200px;
    height: auto;
    position: fixed;
    top: 30px;
    left: 30px;

    svg {
      width: 100%;
    }
  }

  .user-menu-dropdown {
    position: fixed;
    right: 20px;
    top: 15px;

    .page-box {
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 45px;
      min-width: 170px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--main-blue);
        font-weight: 700;
        font-size: 16px;
        user-select: none;
      }
    }
  }

  .page-box {
    position: fixed;
    right: 30px;
    top: 20px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    min-width: 170px;
    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-blue);
      font-weight: 700;
      font-size: 16px;
      user-select: none;
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;

    .content {
      width: 70vw;
      height: 80dvh;
      background: #fff;
      border-radius: 5px;
      color: #1b1b1b;

      & > .container-fluid {
        height: 100%;

        & > .row {
          height: 100%;
          overflow: hidden;
        }
      }

      .left-container {
        height: 100%;
        /*min-height: 700px;*/
        padding: 80px 60px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f9f9f9;
        border-radius: 5px 0 0 5px;

        .content-logo {
          margin-bottom: 30px;
          width: 200px;
        }
      }
      .left-content {
        //width: 400px;
      }

      .right-container {
        height: 100%;
        /*min-height: 700px;*/
        padding: 80px 60px 35px;
        border-radius: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .guest-page {
          justify-content: center;
          width: 100%;

          .guest-content {
            // padding: 0 20px 0;
            overflow: hidden;
            overflow-y: auto;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 20px;

            &.review-details-content {
              justify-content: flex-start;
            }

            &.retrieve-loading-screen {
              min-width: 268px;
            }
          }

          &.loading-screen {
            .guest-content {
              flex-grow: 0;
              min-height: initial;
            }
          }
        }
        .guest-page-register {
          width: 400px;
          justify-content: center;
          .guest-content {
            // padding: 0 20px 0;
            overflow: hidden;
            overflow-y: auto;
            flex-grow: 1;

            &.retrieve-loading-screen {
              min-width: 268px;
            }
          }
        }
        .guest-page-form .guest-title {
          color: var(--blue-title);
          font-size: 20px !important;
          font-family: "Stage Grotesk";
          font-weight: 700;
          margin-bottom: 20px !important;
        }
        .guest-page-register {
          .guest-title {
            color: var(--blue-title);
            font-size: 20px !important;
            font-family: "Stage Grotesk";
            font-weight: 700;
            margin-bottom: 30px !important;
          }

          .login-loading {
            .guest-title {
              margin-bottom: 0 !important;
            }
          }
        }
        .guest-page-form {
          justify-content: center;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow-y: auto;

          .guest-content {
            // padding: 0 20px 0;
            overflow: hidden;
            overflow-y: auto;
            flex-grow: 1;
            max-height: 820px;

            &.retrieve-loading-screen {
              min-width: 268px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1441px) and (max-width: 1920px) {
      .guest-content {
        max-height: 545px !important;
        margin-bottom: 30px !important;
      }
    }
    .dealer-info-banner {
      margin-top: 20px;
      color: #0a1045;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;

      a {
        text-decoration: underline;
      }
    }
  }
  .mt-7 {
    margin-top: 80px;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    .partnership-logo {
      flex: 0 0 33%;
      justify-content: center;
      align-items: center;
      color: #0a1045;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      img {
        max-height: 30px;
      }
    }

    .company-name {
      color: var(--text-gray);
      font-weight: 700;
      font-size: 16px;
      flex: 0 0 33%;

      &.legal-info {
        font-size: 10px;
      }
    }

    .links {
      flex: 0 0 33%;
      display: flex;
      gap: 30px;
      justify-content: flex-end;

      a {
        color: var(--text-gray);
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 998px) and (max-width: 1600px) {
    .page-logo {
      top: 20px;
      left: 20px;
      width: 160px;
    }

    .content-container {
      .content {
        .left-content {
          .feature {
            margin-bottom: 40px;

            &:last-child {
              margin-bottom: 0;
            }

            .feature-title {
              font-size: 16px !important;
            }

            .feature-text {
              font-size: 14px !important;
            }
          }
        }

        .right-content {
          padding: 40px;

          .guest-page {
            .guest-title {
              font-size: 20px;
            }

            .buttons-list {
              button {
                font-size: 14px;
                margin-bottom: 10px;
                padding: 10px 20px;
              }
            }

            .bottom-content {
              margin-top: 20px;
            }
          }
        }

        .left-container {
          padding-top: 40px;
        }

        .right-container {
          padding: 25px 40px;

          .guest-title {
            font-size: 18px;
            margin-bottom: 5px;
            line-height: 24px;
          }

          .guest-page-info {
            margin-bottom: 5px;
            line-height: 22px;
          }

          .progress-tracker {
            margin-bottom: 20px;
            margin-top: 10px;

            .progress-tracker-step {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .progress-tracker-step-icon {
                margin-bottom: 0px;
                svg {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }

          .guest-page-form {
            .guest-title {
              font-size: 18px !important;
              margin-bottom: 10px !important;
            }
            .guest-content {
              margin-bottom: 0px !important;

              .form-container {
                .form-questions {
                  padding-right: 15px;
                }

                .form-item {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0px;
                  }

                  label {
                    font-size: 14px !important;
                  }

                  .button-options {
                    .button-option {
                      font-size: 12px !important;
                    }
                  }

                  input {
                    font-size: 12px !important;
                    padding: 7.5px 10px;
                  }

                  .form-item-info {
                    margin-top: 10px;
                    font-size: 12px;
                  }

                  button {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .guest-page {
            .guest-content {
              justify-content: flex-start;
              margin-bottom: 0 !important;

              .form-section {
                margin-bottom: 0px;

                .form-section-header {
                  .form-section-title {
                    font-size: 14px;
                  }

                  .form-section-action {
                    button {
                      font-size: 10px !important;
                      padding: 2.5px 15px;
                      margin-top: 0;
                    }
                  }
                }

                .form-section-row {
                  margin-bottom: 2.5px;

                  .field-name {
                    font-size: 12px;
                  }

                  .field-value {
                    font-size: 14px;
                  }
                }
              }

              .form-item {
                button {
                  font-size: 14px;
                  padding: 7.5px 10px;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .content-container {
      .content {
        width: 100%;

        .left-container {
          height: 100dvh;
          border-radius: 0;
        }

        .right-container {
          height: 100dvh;
          border-radius: 0;
        }
      }
    }

    footer {
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      background: var(--main-bg-color);
      padding: 10px 20px;

      .company-name {
        flex: 0 0 calc(33% - 5px);
      }

      .partnership-logo {
        flex: 0 0 calc(33% - 5px);
      }

      .links {
        flex: 0 0 calc(33% - 5px);
        display: flex;
        gap: 30px;
        justify-content: flex-end;

        a {
          color: var(--text-gray);
          font-weight: 700;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 992px) {
    &.page-register {
      .content-container {
        .content {
          .right-container {
            .guest-page {
              width: 100%;

              .guest-content {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }

    .guest-page {
      justify-content: center;
      .guest-content {
        padding: 20px !important;
        overflow: hidden;
        overflow-y: auto;
        flex-grow: 1;
      }
      .bottom-content {
        margin-top: 10px;
      }
      .guest-title {
        font-size: 16px;
      }
    }
    .guest-page-form {
      flex-grow: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .guest-content {
        padding: 20px !important;
        overflow: hidden;
        overflow-y: auto;
        flex-grow: 1;
        justify-content: space-between;
      }
      .bottom-content {
        margin-top: 10px;
      }
      .guest-title {
        font-size: 20px;
      }
    }

    display: flex;

    .page-logo {
      width: 100%;
      left: 0;
      top: 10px;
      display: flex;
      justify-content: center;

      svg {
        width: 200px;
      }
    }

    .page-box {
      display: none;
    }

    .content-container {
      width: 95%;
      margin: 0 auto;
      min-height: auto;

      .content {
        border-radius: 0;
        background: transparent;

        .right-container {
          height: auto;
          min-height: auto;
          padding: 0;
          max-height: calc(100dvh - 200px);

          .guest-page-form {
            padding: 20px 5px;
            border-radius: 10px;
            overflow-y: auto;
            background: #fff;

            .guest-title {
              padding-left: 20px;
            }
          }
        }
      }
    }

    footer {
      .partnership-logo {
        font-size: 12px;
      }
      .company-name {
        font-size: 10px;

        &.legal-info {
          font-size: 7.5px;
        }
      }

      .links {
        a {
          font-size: 14px;
        }

        .about-link {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .guest-page-register {
      width: 100% !important;

      .guest-title {
        margin-bottom: 20px !important;
      }
    }
    .right-container {
      max-height: initial !important;
    }
    footer {
      &.dealer-footer {
        flex-direction: column;
        gap: 5px;
      }

      .links {
        flex: 0 0 calc(60% - 5px);
        display: flex;
        gap: 30px;
        justify-content: flex-end;

        a {
          color: var(--text-gray);
          font-weight: 700;
          font-size: 10px;
          text-decoration: none;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  margin-bottom: 80px;
}

::-webkit-scrollbar-track {
  background: #f6fdfe;
}

::-webkit-scrollbar-thumb {
  width: 3px;
  background: #d0d0d0;
  border-radius: 2px;
}
@media screen and (max-width: 767px) {
  .content > .container-fluid > .row {
    height: auto;
  }
}
