.guest-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.middle {
    justify-content: center;
  }

  &.center {
    align-items: center;
  }

  .guest-content {
    &.full-height {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      min-height: 400px;
    }
  }

  .guest-title {
    color: var(--blue-title);
    font-size: 24px;
    font-family: "Stage Grotesk";
    font-weight: 700;
    margin-bottom: 20px;
  }

  .guest-page-info {
    color: #514d4d;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .guest-section {
    margin-bottom: 15px;

    .guest-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--blue-title);

      .guest-section-title {
        color: var(--blue-title);
        font-size: 16px;
        font-weight: 700;
      }

      .guest-section-action {
        button {
          padding: 5px 30px;
          border-radius: 2px;
          border: 1px solid #514d4d;
          color: #514d4d;
          font-size: 12px;
        }
      }
    }
  }

  .terms-section-container {
    display: none;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .terms-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .terms-section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--blue-title);

        .terms-expander {
          display: none;
        }
      }

      .terms-section-body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        color: var(--blue-title);

        ol {
          list-style: decimal;
          list-style-position: inside;

          ol {
            list-style: disc inside;
          }
        }

        .terms-section-terms {
          margin: 20px 0 0;
          border-radius: 5px;
          border: 1px solid #eaeaea;
          padding: 20px 30px;
          overflow-y: auto;

          p {
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    &.open {
      display: flex;
      align-items: stretch;
      overflow-y: hidden;
      margin-bottom: 20px;
      max-height: 100%;
    }

    &.completed {
      display: flex;
      padding-right: 20px;
      padding-top: 5px !important;
      padding-bottom: 10px !important;
      cursor: default;
      user-select: none;

      .terms-section-header {
        & > *:first-child {
          color: #70cf00;
          text-decoration: underline;
          font-weight: 700;
        }
        .terms-expander {
          cursor: pointer;
          display: block;
          color: #606060;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .terms-section-body {
        display: none;
      }
    }
  }

  .guest-section-row {
    display: flex;
    margin-bottom: 10px;

    .field-name {
      color: #514d4d;
      font-size: 14px;
      font-weight: 700;
      width: 200px;
    }

    .field-value {
      color: #514d4d;
      font-size: 14px;
      font-weight: 700;
      width: 200px;
    }
  }

  .or-container {
    font-size: 14px;
    color: var(--text-gray);
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }

  .bottom-content {
    // margin-top: auto;
    display: flex;
    width: 100%;
    gap: 20px;
    color: var(--text-gray);
    font-size: 14px;
    justify-content: center;

    a {
      color: var(--blue-title);
      font-size: 14px;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .buttons-list {
    svg {
      height: 18px;
      width: auto;

      path {
        stroke: #514d4d;
      }
    }
  }

  @media (max-width: 992px) {
    .guest-content {
      background: #fff;
      padding: 15px 25px 10px;
      border-radius: 5px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      .guest-title {
        margin-bottom: 20px;
      }

      .or-container {
        margin: 10px 0;
      }

      .form-container {
        button {
          margin: 0;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .buttons-list {
          button {
            margin-bottom: 10px;
            font-size: 14px;
            padding: 10px 30px 10px 40px;
            justify-content: center;

            svg,
            img {
              height: 18px;
              width: auto;

              path {
                stroke: #514d4d;
              }
            }
          }
        }
      }
    }

    .bottom-content {
      font-size: 12px;
      gap: 15px;

      a {
        font-size: 12px;
      }
    }
  }
}
.guest-page-register {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.middle {
    justify-content: center;
  }

  &.center {
    align-items: center;
  }

  .guest-content-register {
    &.full-height {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
  }

  .guest-title-register {
    color: var(--blue-title);
    font-size: 20px;
    font-family: "Stage Grotesk";
    font-weight: 700;
    margin-bottom: 20px;
  }

  .guest-page-info {
    color: #514d4d;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .guest-section {
    margin-bottom: 15px;

    .guest-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--blue-title);

      .guest-section-title {
        color: var(--blue-title);
        font-size: 16px;
        font-weight: 700;
      }

      .guest-section-action {
        button {
          padding: 5px 30px;
          border-radius: 2px;
          border: 1px solid #514d4d;
          color: #514d4d;
          font-size: 12px;
        }
      }
    }
  }

  .terms-section-container {
    display: none;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .terms-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .terms-section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--blue-title);

        .terms-expander {
          display: none;
        }
      }

      .terms-section-body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        color: var(--blue-title);

        .terms-section-terms {
          margin: 20px 0 0;
          border-radius: 5px;
          border: 1px solid #eaeaea;
          padding: 20px 30px;
          overflow-y: auto;

          p {
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    &.open {
      display: flex;
      align-items: stretch;
      overflow-y: hidden;
      margin-bottom: 0;
    }

    &.completed {
      display: flex;
      margin-bottom: 15px;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      background: #80ea02;
      cursor: default;
      user-select: none;

      .terms-section-header {
        .terms-expander {
          cursor: pointer;
          display: block;
          color: #606060;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .terms-section-body {
        display: none;
      }
    }
  }

  .guest-section-row {
    display: flex;
    margin-bottom: 10px;

    .field-name {
      color: #514d4d;
      font-size: 14px;
      font-weight: 700;
      width: 200px;
    }

    .field-value {
      color: #514d4d;
      font-size: 14px;
      font-weight: 700;
      width: 200px;
    }
  }

  .or-container {
    font-size: 14px;
    color: var(--text-gray);
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }

  .bottom-content-register {
    // margin-top: auto;
    display: flex;
    width: 100%;
    gap: 20px;
    color: var(--text-gray);
    font-size: 14px;
    justify-content: center;

    a {
      color: var(--blue-title);
      font-size: 14px;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .buttons-list {
    svg {
      height: 18px;
      width: auto;

      path {
        stroke: #514d4d;
      }
    }
  }

  @media (max-width: 992px) {
    .guest-content {
      background: #fff;
      padding: 15px 25px 10px;
      border-radius: 5px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      .guest-title {
        margin-bottom: 20px;
      }

      .or-container {
        margin: 15px 0;
      }

      .form-container {
        button {
          margin: 0;
        }

        .buttons-list {
          button {
            margin-bottom: 15px;
            font-size: 14px;
            padding: 10px 30px 10px 40px;
            justify-content: center;

            svg,
            img {
              height: 18px;
              width: auto;

              path {
                stroke: #514d4d;
              }
            }
          }
        }
      }
    }

    .bottom-content {
      font-size: 12px;
      gap: 15px;

      a {
        font-size: 12px;
      }
    }
    .guest-content-register {
      background: #fff;
      padding: 15px 25px 0px;
      border-radius: 5px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 40px;
      /*min-height: 400px !important;*/

      .guest-title-register {
        margin-bottom: 20px !important;
      }

      .or-container {
        margin: 15px 0;
      }

      .form-container {
        button {
          margin: 0;
        }

        .buttons-list {
          button {
            margin-bottom: 15px;
            font-size: 14px;
            padding: 10px 30px 10px 40px;
            justify-content: center;

            svg,
            img {
              height: 18px;
              width: auto;

              path {
                stroke: #514d4d;
              }
            }
          }
        }
      }
    }

    .bottom-content-register {
      font-size: 12px;
      gap: 15px;

      a {
        font-size: 12px;
      }
    }
  }
}
