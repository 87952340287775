.offer-filter {
  display: flex;
  column-gap: 20px;

  a {
    color: #514d4d;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding-bottom: 0px;

    &.selected {
      color: #0a1045;
      border-bottom: 2px solid #0a1045;
    }
  }
}

.offer-filter-mobile {
  button {
    color: #919191;
    text-align: center;
    font-family: "Stage Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 2px;
    border: 2px solid #828282;
  }
}
