@media (max-width: 768px) {
  .guest-layout {
    &.page-register,
    &.page-retrieve {
      .content-container {
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 50px;
        height: calc(100dvh - 145px);
        margin-top: 70px;

        .content {
          height: 100%;
          width: 100%;

          .container-fluid {
            & > .row {
              flex-direction: column;
              flex-wrap: nowrap;

              .right-container {
                height: 100%;

                .guest-page-register {
                  width: 100% !important;

                  .guest-content-register {
                    padding: 15px 15px 0px;

                    .pincode-input-container {
                      justify-content: space-between;
                      margin-bottom: 10px;

                      .pincode-input-text {
                        height: 40px !important;
                        width: 40px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-dealer {
        .content-container {
          height: calc(100dvh - 160px);

          .guest-content-register {
            min-height: unset !important;
          }

          .guest-page {
            width: 100%;

            .guest-content {
              min-height: unset;
              height: 100% !important;
              justify-content: flex-start;
            }
          }

          .guest-page-form {
            .guest-content {
              .form-container {
                height: initial;

                .form-item {
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
