:root {
  --main-blue: #0a1045;
  --blue-title: #212541;
  --text-gray: #514d4d;
  --border-gray: #b9b6b6;
  --outline-button-color: #514d4d;
  --main-bg-color: #e5edf5;
}

@font-face {
  font-family: "Stage Grotesk";
  src: url("../fonts/StageGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stage Grotesk";
  src: url("../fonts/StageGrotesk-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  min-height: 100dvh;
  font-family: "Stage Grotesk";
  background: var(--main-bg-color);
  overflow: hidden;
}

#root {
  background: #e5edf5;
}

.form-error {
  color: red !important;
  font-size: 0.8em !important;
  margin: 5px 0 10px 5px !important;
}

button {
  &.btn-close {
    background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  }
}
.login-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.tooltip > div.tooltip-inner {
  background-color: #0a1045 !important;
  font-weight: 600;
  padding: 15px;
}
.tooltip-arrow {
  display: none !important;
}
.logo-container {
  margin-bottom: 30px;
}
.offers-dealer-logo {
  max-width: 150px;
}
.login-button {
  border: 2px solid #b9b6b6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    border: 2px solid #514d4d;
  }
}
.form-spacing {
  margin-bottom: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

svg {
  &.stroke-warning-yellow {
    path {
      stroke: #664d03;
    }
  }

  &.fill-white {
    path {
      fill: white;
    }
  }
}

.lender-logo {
  mix-blend-mode: multiply;
}
@media screen and (max-width: 767px) {
  .info-exchange {
    font-size: 15px;
  }
}
