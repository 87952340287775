@media screen and (min-width: 993px) {
  .mobile-hide {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .menu-item {
    padding: 5px 0px 0px 20px;
    margin-bottom: 15px;
  }
  .mobile-menu {
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    margin: auto;
    .menu-item {
      font-size: 18px;
    }
  }
  .menu-item-icon {
    height: 20px;
    width: 20px;
  }
  .mobile-header-white {
    background: white;
  }
  .search-bar input {
    border: none;
    outline: none;
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    width: 150px;
  }
  .search-bar svg {
    width: 20px;
  }
  .search-bar {
    padding-left: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 50px;
  }
  .mobile-menu-overlay {
    position: fixed;
    inset: 0;
    z-index: 95;
  }
  .mobile-menu-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 7px 5px 0px rgb(67 67 67 / 26%);
    position: absolute;
    margin-top: 85px;
    z-index: 98;
  }
  .mobile-logout-button {
    width: 350px;
    padding: 15px 30px 15px 20px;
    background-color: var(--main-blue);
    color: #ffffff;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    margin-top: 20px;
  }
  .mobile-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    top: 0px;
    position: fixed;
    z-index: 99;

    width: 100%;
    .logo {
      margin-bottom: 0px;
    }
  }
  .logout-icon {
    width: 20px;
    height: 20px;
  }
}
