.left-hand-quote-details {
  padding: 30px 50px 20px;
  overflow-y: auto;

  .quote-details-logo {
    max-width: 200px;
    margin-bottom: 20px;

    svg {
      width: 100%;
    }
  }

  .quote-details-title {
    color: #0a1045;
    font-size: 24px;
    font-weight: 700;

    svg {
      display: none;
    }
  }

  .quote-details-vehicle {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;

    .quote-details-image {
      border-radius: 20px;
      overflow: hidden;
      width: 50%;
    }

    .quote-details-info {
      .quote-details-vehicle-make {
        color: #0a1045;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      p {
        color: #0a1045;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .quote-details-vehicle-price {
        color: #0a1045;
        font-size: 16px;
        font-weight: 700;

        span {
          font-size: 24px;
        }
      }
    }
  }

  .quote-details-finance-info {
    display: flex;
    gap: 30px;
    margin-bottom: 50px;
    align-items: center;

    .quote-details-image {
      width: 50%;
    }

    .quote-details-info {
      text-align: right;
      .price-per-month {
        color: #0a1045;
        font-size: 32px;
        font-weight: 700;
        margin-right: 15px;
      }

      .price-per-month-label {
        color: #737373;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .quote-actions {
    button {
      &.outline {
        border: 2px solid #919191;
        border-radius: 5px;
        color: #919191;
        outline: none;
        padding: 9px 30px;
      }
    }
  }
}
