.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (min-width: 992px) {
    &.with-margin-bottom {
      margin-bottom: 60px;
    }
  }

  .form-item {
    margin-bottom: 40px;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }

    label {
      color: #7f7f7f;
      font-family: Stage Grotesk;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: block;
      margin-bottom: 13px;
      text-align: left;

      &.thin {
        font-weight: 500;
      }

      &.dark {
        font-weight: 700;
        color: var(--main-blue);
      }
    }

    input {
      border-radius: 5px;
      border: 2px solid #b9b6b6;
      color: #919191;
      width: 100%;
      padding: 10px 15px;
      font-size: 14px !important;

      @media (max-width: 992px) {
        font-size: 16px !important;
        height: 40px;
      }

      &::-webkit-input-placeholder {
        color: #919191;
      }

      & + input {
        margin-top: 20px;
      }
    }

    select {
      border-radius: 5px;
      border: 2px solid #b9b6b6;
      color: #919191;
      width: 100%;
      padding: 10px 25px 10px 15px;
    }

    .pincode-input-container {
      display: flex;
      justify-content: space-evenly;
      margin: 30px 0;

      .pincode-input-text {
        border: 2px solid #b9b6b6 !important;
        color: var(--main-blue);
      }

      @media (max-width: 992px) {
        margin: 10px 0;
      }
    }

    .button-options {
      display: flex;
      align-items: stretch;
      gap: 10px;

      &.showing-all {
        flex-wrap: wrap;
      }

      .button-option {
        border-radius: 5px;
        border: 2px solid #b9b6b6;
        padding: 5px 8px;
        color: #818181;
        font-size: 14px;
        text-align: center;
        min-height: 56px;
        cursor: pointer;
        font-weight: 700;
        flex: 0 0 calc(25% - 7.5px);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected,
        &:focus,
        &:active {
          border-radius: 5px;
          border: 2px solid #88fb00;
          background: #f9f9f9;
          color: #0a1044;
        }

        &:hover {
          border: 2px solid #0a1044;
          color: #0a1044;
        }
      }

      @media (max-width: 568px) {
        flex-wrap: wrap;

        .button-option {
          flex: 0 0 calc(50% - 7.5px);
        }
      }
    }

    .form-item-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 15px;
      color: #5d5d5d;
      font-size: 14px;
      text-decoration: underline;
    }

    .inline-items {
      display: flex;
      gap: 20px;

      input {
        & + input {
          margin-top: 0;
        }
      }
    }
  }

  .form-info {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;

    span {
      color: #514d4d;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      margin-left: 20px;
      font-weight: 600;

      &:not(.success) {
        color: var(--main-blue);
      }
    }
  }

  button {
    &.primary {
      margin-top: 15px;
    }
  }

  .form-link {
    display: block;
    color: #c6c6c6;
    font-family: Stage Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 35px;
    text-align: center;
    text-decoration: none;
  }

  .form-errors {
    margin-top: 5px;

    .form-error {
      color: red;
      font-size: 0.75em;
      padding-left: 10px;
    }
  }
}
.form-container-register {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-item {
    margin-bottom: 5px;

    label {
      color: #7f7f7f;
      font-family: Stage Grotesk;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: block;
      margin-bottom: 10px;
      text-align: left;

      &.thin {
        font-weight: 500;
      }

      &.dark {
        font-weight: 700;
        color: var(--main-blue);
      }
    }

    input {
      border-radius: 5px;
      border: 2px solid #b9b6b6;
      color: #919191;
      width: 100%;
      padding: 10px 15px;
      font-size: 14px !important;

      @media (max-width: 992px) {
        font-size: 16px !important;
      }

      &::-webkit-input-placeholder {
        color: #919191;
      }

      & + input {
        margin-top: 20px;
      }
    }

    select {
      border-radius: 5px;
      border: 2px solid #b9b6b6;
      color: #919191;
      width: 100%;
      padding: 10px 25px 10px 15px;
    }

    .pincode-input-container {
      display: flex;
      justify-content: space-evenly;
      margin: 50px 0;

      .pincode-input-text {
        border: 2px solid #b9b6b6 !important;
        color: var(--main-blue);
      }
    }

    .button-options {
      display: flex;
      align-items: stretch;
      gap: 10px;

      &.showing-all {
        flex-wrap: wrap;
      }

      .button-option {
        border-radius: 5px;
        border: 2px solid #b9b6b6;
        padding: 10px 15px;
        color: #818181;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        font-weight: 700;
        flex: 0 0 calc(25% - 7.5px);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          border-radius: 5px;
          border: 2px solid #88fb00;
          background: #f9f9f9;
        }
        &:hover {
          border: 2px solid #0a1044;
          color: #0a1044;
        }
      }
    }

    .form-item-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 15px;
      color: #5d5d5d;
      font-size: 14px;
      text-decoration: underline;
    }

    .inline-items {
      display: flex;
      gap: 20px;

      input {
        & + input {
          margin-top: 0;
        }
      }
    }
  }

  .form-info {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;

    span {
      color: #514d4d;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      margin-left: 20px;
      font-weight: 600;

      &:not(.success) {
        color: var(--main-blue);
      }
    }
  }

  button {
    &.primary {
      margin-top: 15px;
    }
  }

  .form-link {
    display: block;
    color: #c6c6c6;
    font-family: Stage Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 35px;
    text-align: center;
    text-decoration: none;
  }

  .form-errors {
    margin-top: 5px;

    .form-error {
      color: red;
      font-size: 0.75em;
      padding-left: 10px;
    }
  }
}

.vehicle-reg-input {
  min-width: 200px;
  background: #f4e61b;
  border-radius: 5px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  border: 0;
  text-align: center;
  padding: 7px 10px;
}

.custom-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & ~ span {
        border: 1px solid #88fb00;
        padding: 2px;

        & > span {
          background: #88fb00;
        }
      }
    }
  }

  & > span {
    width: 15px;
    height: 15px;
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    display: block;

    & > span {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.period-container {
  display: flex;
  gap: 30px;

  .period-input {
    width: 60px;

    &.full-year {
      width: 80px;
    }

    label {
      color: #514d4d;
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }

    input {
      border-radius: 3px;
      border: 2px solid #b9b6b6;
      font-size: 16px;
      text-align: center;
      -moz-appearance: textfield;

      &::placeholder {
        color: #ddd;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
