//Hidden components

.offers-ppm-smaller {
  display: none;
}
.offers-lender-small {
  display: none;
}

.offer-filter-mobile {
  display: none;
}

//Large Screen Resolution
@media screen and (min-width: 1981px) {
  .search-bar {
    margin-top: 50px;
    &:hover {
      border: 2px solid #0a1045;
    }
    &:active {
      border: 2px solid #0a1045;
    }
  }
  .gapresp {
    gap: 30px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1980px) {
  .search-bar {
    margin-top: 30px;
    margin-bottom: 30px;
    &:hover {
      border: 2px solid #0a1045;
    }
    &:active {
      border: 2px solid #0a1045;
    }
  }
  .gapresp {
    gap: 20px;
  }
}

@media screen and (min-width: 1980px) {
  .vehicle-information-image {
    img {
      height: 200px !important;
      max-height: 200px !important;
    }
  }
}

@media screen and (min-width: 1339px) {
  .vehicle-information-container {
    flex-grow: 1;
  }

  .quote-filter-bar {
    margin-top: 10px;
  }
  .discont {
    display: contents;
  }
  .quote-details-finance-info .quote-details-image {
    img {
      width: 200px !important;
    }
  }
  .form-questions {
    overflow-y: auto;
    overflow-x: clip;
    max-height: 85%;
  }
}
.quote-details-title {
  font-size: 20px !important;
  margin-bottom: 20px;
}
.quote-details-vehicle .quote-details-image {
  border-radius: 0px !important;
  width: auto !important;
  img {
    width: 200px !important;
  }
}
.left-hand-quote-details
  .quote-details-finance-info
  .quote-details-info
  .price-per-month {
  color: #0a1045;
  font-size: 24px !important;
  font-weight: 700;
  margin-right: 15px;
  text-align: right !important;
}

.left-hand-quote-details {
  .quote-details-vehicle {
    .quote-details-image {
      img {
        width: 275px !important;
        border-radius: 20px;

        @media (min-width: 968px) and (max-width: 1600px) {
          width: 200px !important;
        }
      }
    }

    .quote-details-info {
      width: 50%;
    }
  }

  .quote-details-title {
    margin-bottom: 10px;
  }

  .quote-details-finance-info {
    margin-bottom: 10px;
  }
}

.quote-details-finance-info {
  justify-content: space-between;
}
.form-item label {
  font-size: 16px !important;
}

// Macbook or Small Laptop Resolution

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .form-questions {
    overflow-y: auto;
    overflow-x: clip;
    max-height: 350px;
    padding-right: 20px;
  }
  // .guest-page {
  //   height: auto !important;
  // }
  .period-container .period-input {
    min-width: 100px;
    display: flex;
    gap: 10px;
    align-items: center;
    input {
      min-width: 70px !important;
    }
  }
  .progress-tracker-step-icon {
    width: 25px;
    display: flex;
    justify-content: center;
    height: 20px;
  }
  .button-option {
    font-size: 14px !important;
  }
  .progress-tracker-step {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .progress-tracker {
    margin-top: -20px !important;
  }
  .page-title {
    font-size: 24px !important;
  }
  .page-subtext {
    font-size: 16px !important;
  }
  .logo {
    margin-bottom: 20px !important;
    svg {
      width: 150px !important;
    }
  }
  .menu-item {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .profile-button {
    font-size: 14px !important;
  }
  .logout-button {
    font-size: 14px !important;
  }
  .search-bar {
    margin-bottom: 20px !important;
  }
  .left-hand-content {
    padding: 60px !important;
  }
  .dashboard-content .left-hand-content .content-logo {
    margin-bottom: 30px;
    width: 150px !important;
  }
  .left-hand-content {
    justify-content: center;
  }
  .feature-title {
    font-size: 16px !important;
  }
  .feature-text {
    font-size: 14px !important;
  }
  .feature {
    margin-bottom: 30px !important;
  }
  .profile-passkeys {
    h4 {
      font-size: 16px !important;
    }
    p {
      font-size: 14px !important;
    }
  }
  .create-button {
    font-size: 14px !important;
    padding: 10px !important;
  }
  .links {
    a {
      color: var(--text-gray);
      font-weight: 600;
      font-size: 12px !important;
    }
  }
  .company-name {
    font-size: 12px !important;
  }
  .sidebar-menu {
    width: 270px !important;
  }
  .make-model {
    font-size: 16px !important;
  }
  .price {
    h4 {
      font-size: 16px !important;
    }
  }
  .description {
    font-size: 14px !important;
  }
  .full-quote-button {
    font-size: 16px !important;
    width: 170px !important;
  }
  .checkout-button {
    font-size: 16px !important;
    width: 170px !important;
  }
  .vehicle-image {
    width: 125px !important;
    img {
      border-radius: 0px !important;
    }
  }
  .offers-card {
    padding: 15px !important;
  }
  .guest-layout .content-container .content .left-container {
    height: 100%;
    min-height: 0px !important;
    padding: 50px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 5px 0 0 5px;
    gap: 20px;
  }
  .guest-layout .content-container .content .left-container .content-logo {
    margin-bottom: 0px !important;
    width: 150px !important;
  }
  .guest-layout .content-container .content {
    height: 75dvh !important;
  }
  .guest-layout .content-container .content .right-container {
    min-height: 0px !important;
    padding: 50px !important;

    height: 100%;
  }
  .guest-page .guest-title {
    color: var(--blue-title);
    font-size: 20px !important;
    font-family: "Stage Grotesk";
    font-weight: 700;
    margin-bottom: 20px !important;
  }
  .guest-page-form .guest-title {
    color: var(--blue-title);
    font-size: 20px !important;
    font-family: "Stage Grotesk";
    font-weight: 700;
    margin-bottom: 20px !important;
  }
  .form-item label {
    font-size: 16px !important;
  }

  .quote-checkout-page {
    .checkout-page {
      .form-container {
        .form-item {
          label {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  button {
    font-size: 16px !important;
  }
  .retailer-card {
    width: calc(30% - 20px) !important;
  }
  .retailer-details .vehicle-details {
    font-size: 16px !important;
  }
  .page-title-image {
    width: 100px !important;
    height: 80px;
    object-fit: contain;
  }
  .offers-vehicle-details {
    width: 200px !important;
  }
  .offers-smaller-screen {
    align-items: center;
    width: 200px !important;
  }
  .offers-vehicle {
    gap: 15px !important;
  }
  .offers-ppm h4 {
    font-size: 24px !important;
  }
  .auth-content {
    inset: 0 0 0 350px !important;
    margin-top: 50px !important;
  }
  .checkout-layout .back-link a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #0a1045 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    min-height: 30px !important;
    border-radius: 2px !important;
    border: 0px !important;
    padding: 10px !important;
  }
  .checkout-layout .user-menu-dropdown .page-box div {
    font-size: 14px !important;
  }
  .page-box {
    min-height: 45px !important;
  }
  .back-link {
    svg {
      width: 15px !important;
    }
  }
  .checkout-layout .content-container .content {
    width: 90vw !important;
    height: 78dvh;
    background: #fff;
    border-radius: 5px;
    color: #1b1b1b;
  }
  .checkout-topbar {
    display: flex;
    align-items: center;
  }
  .checkout-layout .left-hand-content {
    padding: 0px !important;
  }
  .info-text {
    font-size: 14px !important;
  }
  .checkout-layout .offers-vehicle {
    width: 200px !important;
  }
  .quote-builder-container .info-text {
    font-size: 12px !important;
    padding-right: 10px;
  }
  .quote-info-label {
    font-size: 12px !important;
  }
  .quote-info-value {
    font-size: 16px !important;
  }
  .quote-details-finance-info .quote-details-image {
    img {
      width: 200px !important;
    }
  }
  .quote-details-title {
    font-size: 20px !important;
    margin-bottom: 20px;
  }
  .quote-details-vehicle .quote-details-image {
    border-radius: 0px !important;
    width: auto !important;
    img {
      width: 200px !important;
    }
  }
  .left-hand-quote-details
    .quote-details-finance-info
    .quote-details-info
    .price-per-month {
    color: #0a1045;
    font-size: 24px !important;
    font-weight: 700;
    margin-right: 15px;
    text-align: right !important;
  }
  .quote-details-finance-info {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
  .left-hand-content {
    padding: 60px !important;

    .logo-container {
      svg {
        margin-bottom: 0 !important;
      }
    }
  }
}

//Ipad or Tablet Resolution

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .form-section {
    .form-section-row {
      justify-content: space-between;

      .field-value {
        text-align: right;
      }
    }
  }

  .offers-card {
    flex-direction: column;
    gap: 10px;
  }
  .offers-vehicle-details {
    border: 0px !important;
  }
  .offers-finance {
    border: 0px !important;
  }
  .offers-smaller-screen {
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
    align-items: center !important;
  }
  .archive-button {
    width: 250px;
  }
  .offers-vehicle {
    width: 100% !important;
  }
  .offers-finance {
    width: 100% !important;
  }
  .offers-buttons {
    flex-direction: row !important;
    width: 100% !important;
    gap: 10px;
    justify-content: space-between;
  }
  .full-quote-button {
    margin-top: 0px !important;
    width: 50% !important;
  }
  .checkout-button {
    margin-top: 0px !important;
    width: 50% !important;
  }
  .retailer-card {
    width: calc(50% - 20px) !important;
  }
  .dashboard-content {
    flex-direction: column;
    overflow-y: auto !important;
  }
  .dashboard-content .right-hand-content {
    display: none;
  }
  .offers-card .offers-ppm {
    display: none;
  }
  .offers-ppm-smaller {
    display: flex;
    width: 250px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 14px;
    color: var(--main-blue);
    h4 {
      font-size: 24px;
      color: var(--main-blue);
      font-weight: 700;
    }
  }

  .vehicle-information-container {
    display: flex;
    gap: 10px;
    padding: 20px;
    align-items: center;

    .vehicle-information-image {
      flex: 0 0 45%;
      margin-bottom: 0;
      min-width: unset;
    }

    .vehicle-information-make-model,
    .vehicle-information-info,
    .vehicle-information-price {
      padding-right: 0;
    }

    .vehicle-information-info {
      font-size: 12px;
    }

    .vehicle-information-price {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }

  .quote-builder-container {
    padding: 20px 20px 25px 20px;
    height: 75%;

    .quote-info {
      padding-right: 0;
    }

    .quote-actions {
      padding-right: 0;
    }
  }

  .offers-lender-small {
    display: flex;
    margin-left: auto;
    margin-right: 40px;
    align-items: center;

    img {
      max-height: 70px;
    }
  }
  .offers-lender {
    display: none;
  }

  .checkout-layout {
    .offers-vehicle {
      align-items: center;
      margin-bottom: 0px;
      width: 60% !important;
    }

    .vehicle-image {
      width: 250px !important;
      img {
        border-radius: 0px !important;
      }
    }
  }

  .offer-filter {
    display: none;
  }

  .offer-filter-mobile {
    display: flex;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .dash-content {
    flex-direction: column !important;
  }
  .profile-details {
    width: 100% !important;
    overflow-y: initial !important;
  }
  .profile-passkeys {
    width: 100% !important;
  }

  .left-hand-content .content-loading {
    padding: 50px !important;
  }

  .checkout-layout
    .content-container
    .content
    .container-fluid
    > .row
    .right-hand-content {
    height: auto !important;
    background: #fff;
    border-radius: 0 5px 5px 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    justify-content: center !important;
    align-items: center !important;
    max-height: 75dvh;
  }
  .checkout-layout
    .content-container
    .content
    .container-fluid
    > .row
    .left-hand-content.content-loading
    .content-logo {
    margin-bottom: 30px !important;
    width: 200px;
  }
  .checkout-layout .offers-vehicle {
    width: 100% !important;
    justify-content: space-between;
  }
  button {
    font-size: 13px !important;
  }
  .quote-builder-container .quote-info {
    gap: 10px;
  }
  .checkout-button {
    font-size: 13px !important;
  }
  button.green {
    padding: 8px 25px !important;
  }

  .quote-info-label {
    font-size: 12px !important;
  }
  button.secondary {
    width: 50% !important;
    border: 0px !important;
  }
  .guest-layout .content-container .content .left-container {
    height: 100% !important;
    padding: 20px 40px !important;
  }
  .guest-layout .content-container .content .right-container {
    height: 100% !important;
  }
  .guest-layout .content-container .content {
    width: 95vw !important;
  }
  .feature {
    margin-bottom: 10px !important;
  }
  .guest-page .or-container {
    font-size: 14px;
    color: var(--text-gray);
    margin: 10px !important;
    width: 100%;
    text-align: center;
  }
  .guest-layout footer {
    flex-direction: row !important;
    background: var(--main-bg-color);
    gap: 10px;
  }
  .offers-container {
    height: 325px !important;
  }
  .form-section .form-section-row {
    display: flex;
    margin-bottom: 5px !important;
  }
  .field-name {
    font-size: 12px !important;
  }
  .right-hand-content .page-content {
    padding: 10px !important;
  }
  .form-section {
    margin-bottom: 5px !important;
  }
  .page-subtext {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
  .page-title {
    text-align: left !important;
    font-size: 16px !important;
    width: 100% !important;
  }
  .form-section-title {
    font-size: 12px !important;
  }
  .field-value {
    font-size: 12px !important;
    width: 180px !important;
  }
  .form-section .form-section-header .form-section-action button {
    font-size: 11px !important;
  }
  .left-hand-content {
    height: auto !important;
  }
  .checkout-title {
    font-size: 16px !important;
  }
  .form-item {
    margin-bottom: 10px !important;
  }
  .quote-builder-container
    .quote-info
    .quote-info-row
    .quote-info-field.vrm-input
    input {
    font-size: 22px !important;
  }
  .quote-builder-container .quote-info .quote-info-row .quote-info-label {
    line-height: initial !important;
  }
  .quote-builder-container .quote-info .quote-px-info p {
    font-size: 11px !important;
  }
  .form-container .form-item .form-item-info {
    font-size: 11px !important;
  }

  .full-quote-info-container .quote-row > .col-12 {
    padding: 0px 10px !important;
  }
  .full-quote-info-container .quote-actions {
    justify-content: center !important;
  }
  .left-hand-quote-details
    .quote-details-finance-info
    .quote-details-info
    .price-per-month {
    margin-right: 0px !important;
  }

  .offer-filter {
    display: none;
  }

  .offer-filter-mobile {
    display: flex;
  }
}
//Mobile Resolution

@media screen and (max-width: 992px) {
  .guest-layout {
    .content-container {
      .content {
        .container-fluid {
          .row {
            .right-container {
              .loading-screen {
                justify-content: center !important;

                .guest-content.full-height {
                  height: auto !important;
                  padding: 25px !important;
                }

                .login-loading {
                  width: 375px;
                  min-height: 275px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }

    .guest-title {
      margin-top: 0px !important;
    }

    .terms-section-container {
      padding: 15px !important;

      &.completed {
        margin-bottom: 5px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      .terms-section {
        /*max-height: 325px;*/

        .terms-section-header {
          & > div:first-child {
            font-size: 16px;
          }
        }
      }
    }

    .dealer-info-banner {
      display: none;
      position: absolute;
      bottom: 135px;
      left: 20px;
      right: 20px;
      text-align: center;
    }

    .content-container {
      .content {
        .left-container {
          display: none;
        }

        .right-container {
          .progress-tracker {
            margin-top: 10px;
            margin-bottom: 20px;

            .progress-tracker-step {
              background: transparent;
              padding: 0 10px;
              text-align: center;

              &-icon {
                svg {
                  height: 20px;
                }
              }

              &-text {
                background: #e5edf5;
              }
            }
          }

          .button-options {
            flex-wrap: wrap;

            .button-option {
              flex: 0 0 calc(50% - 5px);
            }
          }

          .guest-page {
            justify-content: center;

            &.terms-page {
              height: calc(100dvh - 200px);
              overflow-y: auto;
              justify-content: flex-start;

              .terms-container {
                overflow-y: unset;
                padding-right: 0;
              }
            }

            &.middle {
              justify-content: center;
            }

            .bg {
              background: #fff;
              padding: 20px;
              border-radius: 5px;

              .guest-title {
                margin-top: 0;
              }
            }

            .guest-title {
              margin-top: 30px;
              margin-bottom: 20px;
            }

            .guest-content {
              &.full-height {
                height: calc(100dvh - 350px);
                padding: 0 !important;

                &.retrieve-loading-screen {
                  justify-content: center;
                  width: 100% !important;
                  padding: 20px 40px !important;
                  height: unset !important;
                  flex-grow: 0;

                  .guest-title {
                    font-size: 28px;
                  }
                }

                .form-container {
                  padding: 20px;
                }

                & > .form-item {
                  padding: 0 20px 20px;
                }

                .form-section {
                  padding: 20px 20px 0;
                  margin-bottom: 5px;

                  .form-section-content {
                    .form-section-row {
                      gap: 10px;

                      .field-name {
                        min-width: 50%;
                        width: 50%;
                      }

                      .field-value {
                        min-width: 50%;
                        width: 50%;
                      }
                    }
                  }
                }
              }
            }

            .terms-section-container {
              box-shadow: none;
              background: #fff;
              height: auto;

              &.completed {
              }
            }
          }
        }
      }
    }
  }

  .auth-layout {
    .left-hand-content {
      padding-left: 20px;
    }

    .offers-grid,
    .applications-grid {
      .offers-card {
        & > div {
          order: 3;
        }

        .offers-vehicle {
          order: 2;
        }

        .offers-lender {
          order: 1;
          padding-top: 10px;

          img {
            width: 50%;
            margin: 0 auto;
          }
        }
      }
    }

    .passkeys-list {
      & > div {
        flex-wrap: wrap;
        gap: 20px !important;

        & > div {
          flex: 0 0 75%;

          &:first-child {
            flex: 0 0 10%;
          }

          &:last-child {
            flex: 0 0 100%;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .checkout-layout {
    display: block;

    .content-container {
      margin-top: 0;
      margin-bottom: 0;

      .content {
        .loading-screen {
          height: calc(100dvh - 145px) !important;
        }

        .container-fluid {
          &.offer-details-page {
            &.container-loading {
              & > .row {
                .right-hand-content {
                  background: #fff;
                  border-radius: 5px;
                  padding: 20px;
                }
              }
            }
          }
        }

        .container-fluid {
          &.offer-details-page {
            &.container-loading {
              display: flex;
              align-items: center;
              height: calc(100dvh - 142px);
            }

            & > .row {
              .left-hand-content {
                background: transparent;
                padding: 0 10px 0 !important;

                .vehicle-information-container {
                  background: #fff;
                  padding: 15px;
                  margin-bottom: 0px;
                  border-radius: 5px;

                  .vehicle-information-header {
                    font-size: 15px;
                    font-weight: 700;
                    user-select: none;
                    display: flex;
                    justify-content: space-between;

                    svg {
                      transform: rotate(180deg);

                      path {
                        stroke: rgb(27, 27, 27);
                      }
                    }
                  }

                  &.mobile-open {
                    .vehicle-information-header {
                      padding-bottom: 15px;

                      svg {
                        transform: rotate(0deg);
                      }
                    }
                  }

                  .vehicle-information-image {
                    margin-bottom: 0;
                  }

                  .vehicle-information-info-container {
                    flex: 0 0 50%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    div {
                      padding-right: 0;
                    }

                    .vehicle-information-make-model {
                      font-size: 18px;
                    }

                    .vehicle-information-info {
                      font-size: 12px;
                    }

                    .vehicle-information-price {
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 0;
                    }
                  }
                }

                .quote-builder-container {
                  border-radius: 5px;
                  display: none;

                  .quote-info {
                    gap: 10px;
                  }
                }
              }

              .right-hand-content {
                background: transparent;
                padding: 10px;

                .offers-container {
                  padding: 0;
                  overflow-x: hidden;
                  padding-bottom: 0px;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  .offers-card {
                    padding: 20px;
                    margin-bottom: 0;
                    flex-direction: column;

                    .lender-logo {
                      width: auto;
                      height: 40px;
                    }

                    .offers-ppm-smaller {
                      display: flex;
                      width: 250px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      font-weight: 700;
                      font-size: 14px;
                      color: var(--main-blue);

                      h4 {
                        font-size: 24px;
                        color: var(--main-blue);
                        font-weight: 700;
                      }
                    }
                  }
                }

                .quote-builder-container {
                  position: fixed;
                  bottom: 60px;
                  left: calc(5% + 10px);
                  right: calc(5% + 10px);
                  height: auto;
                  border-radius: 5px;
                  padding: 10px 5px 20px 25px;
                  z-index: 100;

                  &.mobile-closed {
                    padding: 10px 20px;
                    min-height: initial;
                  }

                  &.mobile-open {
                    //height: calc(100dvh - 180px);
                  }

                  .quote-info {
                    gap: 10px;
                  }

                  .mobile-builder-toggle,
                  .mobile-builder-close {
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    font-family: "Stage Grotesk";
                    font-size: 15px;
                    font-weight: 700;
                  }

                  .mobile-builder-close {
                    padding: 10px 25px;

                    svg {
                      transform: rotate(180deg);
                    }
                  }
                }

                .quote-changed-overlay {
                  .quote-changed-content {
                    max-width: 90%;
                    font-size: 14px;
                  }
                }

                .full-quote-info-container {
                  padding: 10px 10px 20px;

                  .quote-row {
                    .quote-info-label {
                      font-size: 16px;
                    }

                    .quote-info-value {
                      font-size: 16px;
                    }
                  }

                  .quote-actions {
                    margin-top: 10px;

                    button {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        .quote-checkout-page {
          .right-hand-content {
            margin-top: 20px;
            padding: 15px !important;

            .progress-tracker {
              width: 100% !important;
            }

            .page-content {
              padding: 0 !important;

              .form-section {
                padding: 20px 20px 0 !important;
                margin-bottom: 5px;

                .form-section-content {
                  .form-section-row {
                    gap: 10px;

                    .field-name {
                      min-width: 50%;
                      width: 50%;
                    }

                    .field-value {
                      min-width: 50%;
                      width: 50%;
                    }
                  }
                }
              }
            }

            .review-details-actions {
              padding: 0 20px;
            }
          }
        }
      }

      .left-hand-quote-details {
        padding: 15px 20px;

        .quote-details-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          svg {
            display: block;

            path {
              stroke: #0a1045;
            }
          }
        }

        &.mobile-closed {
          .quote-details-title {
            margin-bottom: 0;

            svg {
              transform: rotate(180deg);
            }
          }

          .quote-details-container {
            display: none;
          }
        }

        .quote-details-logo {
          display: none;
        }
      }
    }

    .offer-filter {
      justify-content: space-between;
      text-align: center;
      align-items: center;
    }

    footer {
      background: var(--main-bg-color);
    }
  }

  .offer-filter {
    display: none;
  }

  .offer-filter-mobile {
    display: flex;
    button {
      background: #ffffff00;
    }
  }
  .full-quote-button {
    font-size: 14px;
  }
  .checkout-button {
    font-size: 14px;
  }
  .quote-actions {
    gap: 20px !important;
    margin-bottom: 0px;
    .secondary {
      font-size: 16px;
    }
  }
  .quote-info-label {
    font-size: 14px !important;
  }
  .info-row {
    margin-bottom: 10px !important;
    img {
      max-width: 100% !important;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 10px;
    }
  }
  .text-right {
    text-align: center !important;
  }
  .price-per-month {
    margin-right: 0px !important;
  }
  .page-subtext {
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .form-section {
    padding: 0px !important;
  }
  .page-content {
    overflow-x: clip;
  }
  button {
    font-size: 16px;
  }
}

@media screen and (min-width: 1900px) {
  .left-hand-quote-details {
    .quote-details-vehicle {
      .quote-details-image {
        & > img {
          width: 400px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  .vehicle-information-image img {
    max-height: 300px !important;
  }
  .quote-info-label {
    font-size: 14px !important;
  }
  .quote-info {
    justify-content: flex-start !important;
    gap: 40px;
  }
  .quote-info-vrm {
    justify-content: flex-start;
    gap: 30px;
  }
}
