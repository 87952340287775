.notifications-container {
  .notification-item-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    .notification-content {
      display: flex;
      flex: 0 0 100%;
      background: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      flex-direction: column;

      .notification-text {
        color: var(--blue-title);
        font-weight: 600;
      }

      .notification-time {
        font-size: 0.8em;
        color: #514d4d;
      }
    }
  }
}
