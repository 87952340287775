.vehicle-information-container {
  width: 100%;
  padding: 20px 20px 10px;
  .vehicle-information-image {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
    flex: 0 0 50%;
    //min-width: 290px;

    img {
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 3 / 2;
      min-height: 100px;
    }
  }

  .vehicle-information-make-model {
    color: #0a1045;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    padding: 0 13px;
  }

  .vehicle-information-info {
    color: #0a1045;
    font-size: 12px;
    font-weight: 500;
    padding: 0 13px;
    margin-bottom: 5px;
  }

  .vehicle-information-price {
    padding: 0 13px;
    display: flex;
    flex-direction: column;

    label {
      color: #0a1045;
      font-size: 12px;
      font-weight: 700;
    }

    span {
      color: #0a1045;
      font-size: 18px;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 1440px) {
    & > div {
      width: 100%;

      &.vehicle-information-header {
        display: none;
      }
    }

    img {
      max-height: 160px;
    }
  }

  @media screen and (min-width: 1600px) {
    padding: 15px 15px 10px;

    & > div {
      &.vehicle-information-header {
        display: block;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }

    .vehicle-information-info-container {
      display: flex;
      flex-direction: column;
    }

    .vehicle-information-image {
      min-width: initial;
      flex: 0 0 50%;

      img {
        max-height: unset;
      }
    }

    .vehicle-information-make-model {
      font-size: 24px;
      line-height: 1.2em;
    }

    // .vehicle-information-info {
    //   font-size: 16px;
    //   color: #939393;
    //   margin-top: 5px;
    // }

    .vehicle-information-price {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      label {
        font-size: 16px;
      }

      span {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    img {
      max-height: 220px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1600px) {
    /*
    .gapresp {
      flex-direction: column;
    }
    */

    .vehicle-information-header {
      color: #0a1045;
      font-size: 20px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    .gapresp {
      flex-direction: column;

      .vehicle-information-image {
        min-width: unset;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    &.mobile {
      display: none;
    }

    .vehicle-information-header {
      color: #0a1045;
      font-size: 20px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
