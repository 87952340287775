$no-of-el: 5;
$size: 20px;
$margin: 5px;
$offset: 0.25s;
$color: var(--main-blue);

@keyframes waterfall {
  0% {
    opacity: 0;
    transform: translateY(-250%);
  }
  40%,
  60% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(250%);
  }
}

.waterfall {
  div {
    animation: waterfall 1.5s infinite;
    background-color: $color;
    height: $size;
    left: 50%;
    margin-top: -($size / 2);
    opacity: 0;
    position: absolute;
    top: 50%;
    width: $size;

    @for $el from 1 through $no-of-el {
      &:nth-of-type(#{$el}) {
        $marg: 0;
        @if ($el == 1) {
          $marg: ($size / -2);
        }
        @if ($el > 1) {
          $multiplier: $el;
          @if (($el % 2) > 0) {
            $multiplier: -($el - 1);
          }
          $marg: ((($multiplier / 2) - 1) * $size) +
            (($multiplier / 2) * $margin) +
            ($size / 2);
        }
        animation-delay: $el * $offset;
        margin-left: $marg;
      }
    }
  }
}

$no-of-el: 5;
$size: 20px;
$margin: 5px;
$offset: 0.25s;
$color: var(--main-blue);

@keyframes waterfall2 {
  0% {
    opacity: 0;
    transform: translateY(-250%);
  }
  40%,
  60% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(250%);
  }
}

.waterfall2 {
  div {
    animation: waterfall 1.5s infinite;
    background-color: $color;
    height: $size;
    opacity: 0;
    position: absolute;
    width: $size;

    @for $el from 1 through $no-of-el {
      &:nth-of-type(#{$el}) {
        $marg: 0;
        @if ($el == 1) {
          $marg: ($size / -2);
        }
        @if ($el > 1) {
          $multiplier: $el;
          @if (($el % 2) > 0) {
            $multiplier: -($el - 1);
          }
          $marg: ((($multiplier / 2) - 1) * $size) +
            (($multiplier / 2) * $margin) +
            ($size / 2);
        }
        animation-delay: $el * $offset;
        margin-left: $marg;
      }
    }
  }
}

.loader-icon {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: draw 2s ease-in-out alternate;
  animation-iteration-count: infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
