.sidebar-menu-container {
  height: 100dvh;
  padding-top: 50px;
  padding-bottom: 70px;
}

.sidebar-menu {
  width: 290px;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 40px 30px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}

.logo {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 200px;
  }
}

.search-bar {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid #b9b6b6;
  display: flex;
  align-items: center;
  svg {
    height: 20px;
    width: 15px;
    path {
      stroke: #514d4d;
    }
  }
}
.search-bar input {
  border: none;
  outline: none;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
}
.search-bar input::placeholder {
  color: #514d4d;
  font-weight: 500;
}

.menu-items {
  margin-bottom: 15px;
  flex-direction: column;

  @media (min-width: 2200px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.menu-item {
  padding: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  color: var(--blue-title);
  cursor: pointer;

  @media (min-width: 1950px) {
    margin-bottom: 10px;
  }
}
.menu-item-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
a {
  text-decoration: none;
}
.menu-item-icon {
  margin-right: 20px;
}
.menu-item:hover {
  background-color: var(--border-gray);
  border-radius: 5px;
}

.logout-button {
  width: 100%;
  background-color: var(--main-blue);
  color: #ffffff;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  height: 45px;
}
.logout-icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.logout-button:hover {
  background-color: #101969;
}
.profile-button {
  width: 100%;
  background: #fff;
  border: 2px solid #b9b6b6;
  color: var(--main-blue);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 15px;
  margin-top: 0;
}
.profile-icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.profile-button:hover {
  background-color: var(--border-gray);
}

@media screen and (max-width: 992px) {
  .sidebar-menu {
    display: none;
  }
  .sidebar-menu-container {
    display: none;
  }
}

@media (min-width: 998px) and (max-width: 1600px) {
  .sidebar-menu {
    padding-top: 25px;
  
    .search-bar {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .menu-items {
      .menu-item {
        margin-bottom: 0;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}