.quote-status {
  padding: 2.5px 15px;
  border-radius: 10px;
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 8px;

  &.status- {
    display: none;
  }

  &.status-approved {
    background: #86efac;
    color: #166534;
  }

  &.status-declined {
    background: #fca5a5;
    color: #991b1b;
  }

  &.status-pending {
    background: #7dd3fc;
    color: #075985;
  }

  &.status-referred {
    background: #fcd34d;
    color: #92400e;
  }
}
