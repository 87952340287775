.full-quote-info-container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;

  &.embedded {
    box-shadow: none;
    background: transparent;
    padding: 10px;
    border-radius: 0;
    height: 100%;

    .quote-actions {
      margin-top: 30px;
    }
  }

  .info-row {
    margin-bottom: 40px;

    .lender-logo {
      max-width: 200px;
    }

    .price-per-month {
      color: #0a1045;
      font-size: 32px;
      font-weight: 700;
    }

    .price-per-month-label {
      color: #737373;
      font-size: 16px;
      font-weight: 700;
      margin-left: 15px;
    }
  }

  .quote-row {
    & > .col-12 {
      padding: 0 30px;
    }

    .row {
      & > div {
        display: flex;
        justify-content: space-between;

        .quote-info-label {
          color: #7f7f7f;
          font-size: 20px;
          font-weight: 400;
          min-width: 50%;

          svg {
            display: inline-block;
            margin-left: 10px;
          }
        }

        .quote-info-value {
          color: #0a1045;
          font-size: 20px;
          font-weight: 700;
          text-align: right;
        }
      }
    }
  }

  .quote-actions {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
  }
}
