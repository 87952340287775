@media (max-width: 992px) {
  .checkout-layout {
    &.page-offerdetails {
      .content-container {
        height: calc(100dvh - 150px);

        .content {
          height: 100%;
          overflow-y: auto;

          .offer-details-page {
            &.container-loading {
              height: 100% !important;
            }

            & > .row {
              .right-hand-content {
                margin-bottom: 0 !important;

                .quote-filter-bar {
                  margin-left: 0;
                  margin-right: 0;
                  padding-left: 0 !important;
                  padding-right: 0 !important;

                  & > div {
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        &:has(.container-loading) {
          width: 95%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .checkout-layout {
    &.page-offerdetails {
      .content-container {
        .content {
          .offer-details-page {
            & > .row {
              .left-hand-content {
                .vehicle-information-container {
                  .flex {
                    flex-direction: row;
                  }
                  .vehicle-information-image {
                    min-width: 150px;
                  }
                  .vehicle-information-header {
                    color: #0a1044;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
