.checkout-layout {
  background: var(--main-bg-color);
  min-height: 100dvh;

  .page-logo {
    svg {
      height: 100%;
      width: 190px;
    }

    @media (max-width: 992px) {
      top: 0;
      left: 0;
      right: 0;
      height: auto;
      background: var(--main-bg-color);
      z-index: 98;

      svg {
        margin: 0 0 0 0vw;
        max-height: 25px;
        width: auto;
      }
    }
  }
  .checkout-topbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;

    @media (max-width: 992px) {
      align-items: center;
    }
  }
  .checkout-topbar-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .back-link {
    width: 200px;
    a {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #0a1045 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      min-height: 30px !important;
      justify-content: space-evenly;
      border-radius: 2px !important;
      border: 0px !important;
      padding: 10px !important;
    }
  }

  .user-menu-dropdown {
    width: 180px;

    .page-box {
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 45px;
      min-width: 170px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--main-blue);
        font-weight: 700;
        font-size: 16px;
        user-select: none;
      }
    }
  }
  .dropdown-menu {
    border: none !important;
    border-radius: 0px !important;
    font-weight: 700 !important;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: var(--main-blue);
  }
  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90dvh;
    padding-bottom: 50px;

    .content {
      width: 90vw;
      height: 78dvh;
      background: #fff;
      border-radius: 5px;
      color: #1b1b1b;

      .full-recalc-loading-container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        .recalc-loading-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .loading-title {
            color: var(--blue-title);
            font-size: 28px;
            font-family: "Stage Grotesk";
            font-weight: 700;
          }

          .loading-info-text {
            max-width: 60%;
            color: var(--blue-title);
            font-size: 20px;
            font-family: "Stage Grotesk";
            font-weight: 700;
            margin-top: 40px;
          }

          .loading-animation {
            margin-top: 40px;
          }
        }
      }

      .container-fluid {
        height: 100%;

        & > .row {
          height: 100%;

          .left-hand-content {
            height: 100%;
            background: #f9f9f9;
            border-top-left-radius: 5px;
            padding: 0px;
            overflow-y: hidden;

            &.content-loading {
              padding: 80px 120px 30px !important;
              display: flex;
              justify-content: center;
              flex-direction: column;
              @media screen and (min-width: 993px) and (max-width: 1024px) {
                padding: 60px !important;
              }

              .content-logo {
                margin-bottom: 20px;
                width: 200px;
              }
            }

            &:not(.content-loading) > div {
              height: 100%;
            }
          }

          .right-hand-content {
            height: 100%;
            background: #fff;
            border-radius: 0 5px 5px 0;
            padding: 40px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;

            .quote-filter-bar {
              color: #b9b9b9;
              font-size: 12px;
              font-weight: 700;
              line-height: 24px;
              width: 100%;
              padding-left: 15px;
            }

            .offers-container,
            .page-content {
              padding: 20px;
              overflow-y: auto;

              &.quote-changed-container {
                overflow: hidden;
                padding: 0;

                @media (max-width: 1024px) {
                  position: relative;
                }
              }
            }

            .quote-changed-overlay {
              position: absolute;
              backdrop-filter: blur(10px);
              z-index: 98;
              inset: 0;
              background: #ffffffa6;
              display: flex;
              align-items: flex-start;
              justify-content: center;

              @media (min-width: 1024px) {
                align-items: center;
              }

              .quote-changed-content {
                background: transparent;
                padding: 20px 40px;
                max-width: 60%;
                border-radius: 5px;
                text-align: center;
                font-weight: 600;
                color: #0a1045;

                .quote-actions {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 50px;

                  button {
                    width: 50%;
                    color: #0a1045;
                    margin-top: 0;
                    font-size: 13px;
                    border-radius: 3px;
                    padding: 8px 30px;

                    @media (max-width: 1024px) {
                      width: 100%;
                    }
                  }
                }

                @media (max-width: 992px) {
                  max-width: 100%;
                  padding: 20px;
                }
              }
            }

            .recalc-loading-container {
              height: 100%;
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              color: #0a1045;
              font-size: 24px;
              font-weight: 700;
            }

            .progress-tracker {
              width: 50%;
              margin: 0 auto 30px;
            }

            .checkout-page {
              display: flex;
              flex-direction: column;
              height: 100%;
              overflow: hidden;

              .checkout-content {
                margin-bottom: 40px;

                &.full-height {
                  display: flex;
                  flex-direction: column;
                  justify-content: stretch;
                  overflow-y: auto;
                  padding: 0 20px;

                  @media (max-width: 768px) {
                    margin-bottom: 100px;
                  }
                }

                .form-container {
                  .form-button {
                    position: absolute;
                    bottom: 0;
                    left: 60px;
                    right: 60px;
                    padding-top: 10px;
                    background: #fff;

                    @media (max-width: 1024px) {
                      left: 50px;
                      right: 50px;
                      bottom: 10px;
                    }
                  }

                  @media (max-width: 768px) {
                    margin-bottom: 20px;

                    .form-button {
                      left: 35px;
                      right: 35px;
                    }
                  }
                }
              }

              .checkout-title {
                color: var(--blue-title);
                font-size: 24px;
                font-family: "Stage Grotesk";
                font-weight: 700;
                margin-bottom: 20px;
                padding: 0 20px;
              }

              .page-subtext {
                padding: 0 20px;
              }
            }

            .sort-code-row {
              input {
                text-align: center;
              }
            }

            .application-checkout-questions {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              height: 100%;
              padding: 10px;

              h4 {
                color: #0a1045;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 22px;
              }

              p {
                color: #0a1045;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 22px;

                &.text-grey {
                  color: #514d4d;
                }

                span {
                  color: #00a6ff;
                  font-weight: 700;
                }
              }

              &.consumer-support-page {
                justify-content: flex-start;
                overflow-y: auto;

                h3 {
                  color: #0a1045;
                  font-size: 16px;
                  font-weight: 700;
                }

                p {
                  color: #636466;
                  font-size: 16px;
                  font-weight: 400;
                }

                div {
                  & > h4 {
                    color: #0a1045;
                    font-size: 16px;
                    font-weight: 700;
                  }

                  & > a {
                    color: #283eff;
                    font-size: 16px;
                    font-weight: 400;
                  }
                }
              }
            }

            .decision-page {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;

              .decision-content {
                padding: 0 40px;
                height: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .decision-icon {
                  width: 100px;
                  margin: 0 auto 30px;
                }

                .decision-title {
                  color: #0a1045;
                  text-align: center;
                  font-size: 20px;
                  font-weight: 700;
                  margin-bottom: 15px;

                  &.large {
                    font-size: 24px;
                  }
                }

                .decision-text {
                  color: #939393;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 700;
                  padding: 0 30px;
                }
              }

              .decision-action {
                margin-top: auto;
                width: 100%;

                button {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .mt-7 {
    margin-top: 80px;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;

    .company-name {
      color: var(--text-gray);
      font-weight: 700;
      font-size: 16px;
    }

    .links {
      display: flex;
      gap: 30px;

      a {
        color: var(--text-gray);
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1600px) {
    .content-container {
      .content {
        .quote-checkout-page {
          .left-hand-content {
            .left-hand-quote-details {
              padding: 20px 40px;

              .quote-details-logo {
                width: 150px;
                margin-bottom: 0px;
              }

              .quote-details-vehicle {
                margin-bottom: 10px;

                .quote-details-image {
                  img {
                    width: 225px !important;
                  }
                }
              }
            }
          }

          &.container-fluid {
            & > .row {
              .right-hand-content {
                padding: 20px 40px;

                .page-title {
                  font-size: 20px;
                }

                .page-subtext {
                  margin-bottom: 0px !important;
                  font-size: 16px;
                }

                .page-content {
                  padding: 0px 20px 0 0;

                  .form-section {
                    margin-bottom: 0px;

                    .form-section-header {
                      .form-section-action {
                        button {
                          font-size: 10px !important;
                          padding: 5px 15px;
                        }
                      }
                    }

                    .form-section-content {
                      .form-section-row {
                        margin-bottom: 0px;

                        .field-name {
                          font-size: 14px;
                        }

                        .field-value {
                          font-size: 14px;
                          flex-grow: 1;
                        }
                      }
                    }
                  }

                  .review-details-actions {
                    margin-top: 20px;
                  }
                }

                .progress-tracker {
                  margin-bottom: 10px;

                  .progress-tracker-step {
                    .progress-tracker-step-icon {
                      margin-bottom: 0px;

                      svg {
                        width: 16px;
                        height: 16px;
                      }
                    }
                  }
                }

                .checkout-page {
                  .checkout-title {
                    font-size: 20px;
                    margin-bottom: 10px;
                  }

                  .checkout-content {
                    margin-top: 15px;
                    margin-bottom: 50px;
                    .form-container {
                      .form-item {
                        margin-bottom: 15px;

                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .back-link {
      width: auto;
    }

    .user-menu-dropdown {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .content-container {
      .content {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 992px) {
    .checkout-page {
      justify-content: center;
      .guest-content {
        padding: 20px !important;
        overflow: hidden;
        overflow-y: auto;
      }
      .bottom-content {
        margin-top: 10px;
      }
      .guest-title {
        font-size: 20px;
      }
    }

    display: flex;

    .page-box {
      display: none;
    }

    .content-container {
      width: 90%;
      margin: 70px auto;
      min-height: auto;

      .content {
        border-radius: 0;
        background: transparent;

        .right-container {
          height: auto;
          min-height: auto;
          padding: 0;
        }

        .container-fluid {
          & > .row {
            height: auto;

            .left-hand-content {
              &.content-loading {
                display: none;
                background: none;
                padding: 20px;
                height: auto;

                .feature {
                  display: flex;
                  gap: 20px;
                  margin-bottom: 20px;

                  .feature-icon {
                    position: initial;
                  }

                  .feature-title {
                    font-size: 16px;
                  }
                }

                .logo-container {
                  display: none;
                }
              }
            }

            .right-hand-content {
              height: unset;

              .loading-info-text {
                max-width: 100%;
              }
            }
          }

          &.offer-details-page {
            &:not(.container-loading) {
              & > .row {
                height: unset;
                max-height: unset;

                .left-hand-content {
                  max-height: unset;
                  height: unset;
                }

                .right-hand-content {
                  max-height: unset;
                  height: unset;
                  margin-bottom: 40px;
                }
              }
            }
          }

          &.quote-checkout-page {
            & > .row {
              height: calc(100dvh - 145px);

              .left-hand-content {
                height: unset;
              }

              .right-hand-content {
                height: calc(100% - 80px);
              }
            }
          }
        }
      }
    }

    footer {
      .company-name {
        font-size: 14px;
      }

      .links {
        a {
          font-size: 14px;
        }

        .about-link {
          display: none;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  margin-bottom: 80px;
}

::-webkit-scrollbar-track {
  background: #f6fdfe;
}

::-webkit-scrollbar-thumb {
  width: 3px;
  background: #d0d0d0;
  border-radius: 2px;
}

.fixed-checkout-button {
  position: absolute;
  bottom: 50px;
  right: 50px;
  left: 50px;

  @media (max-width: 768px) {
    left: 20px;
    right: 20px;
  }
}

.fixed-button-section {
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
}
