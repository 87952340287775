@media (max-width: 768px) {
  .checkout-layout {
    &.page-quotecheckout {
      .content-container {
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 50px;
        padding-right: 10px;
        height: calc(100dvh - 145px);

        .content {
          height: 100%;

          .quote-checkout-page {
            & > .row {
              flex-direction: column;
              flex-wrap: nowrap;

              .right-hand-content {
                height: unset;

                .checkout-page {
                  .checkout-title {
                    padding: 0 10px;
                  }

                  .form-container {
                    margin-bottom: 10px;
                  }

                  .checkout-content {
                    margin-bottom: 0;
                    padding: 0 10px;
                    overflow-x: hidden;

                    .form-button {
                      position: initial;
                      margin-top: 0;
                      margin-bottom: 0;
                      padding-top: 0;
                    }
                  }
                }

                .page-content {
                  .form-section {
                    padding: 20px 10px 0 !important;
                  }
                }

                .application-checkout-questions {
                  margin-bottom: 0;

                  & > p {
                    margin-bottom: 0;

                    &.text-grey {
                      margin-bottom: 20px;
                    }
                  }

                  .fixed-checkout-button {
                    position: initial;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
