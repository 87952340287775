.info-header {
  color: #0a1045;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;

  span {
    color: #283eff;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.info-text {
  color: #514d4d;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 17px;
  }
}

.warning-text {
  color: #514d4d;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
  }
}
