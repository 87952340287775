.progress-tracker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: -50px;
  max-width: 350px;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 30px;
  }

  .progress-tracker-step {
    position: relative;
    background: #fff;

    &:not(:first-child) {
      padding-left: 15px;
    }

    &:not(:last-child) {
      padding-right: 15px;
    }

    .progress-tracker-step-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      height: 20px;
    }

    .progress-tracker-step-text {
      color: #d9d9d9;
      font-size: 12px;
      background: #fff;
    }

    &.selected {
      cursor: pointer;

      .progress-tracker-step-icon {
        svg {
          &.stroke {
            path {
              stroke: #538d12;
            }
          }

          &.fill {
            path {
              fill: #538d12;
            }
          }
        }
      }

      .progress-tracker-step-text {
        color: #538d12;
        font-weight: 700;
      }
    }
  }
}
