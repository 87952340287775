.lender-grid {
  .lender-card {
    padding: 10px 20px;

    .lender-image {
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      height: 150px;
      padding: 10px 20px;
      overflow: hidden;

      @media (max-width: 768px) {
        height: 125px;
      }

      @media (max-width: 640px) {
        height: 100px;
      }

      img {
        max-width: 100px;
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      padding: 10px;
    }
  }

  @media (max-width: 768px) {
    padding-right: 5%;
  }
}
