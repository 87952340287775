.auth-layout {
  background: var(--main-bg-color);
  min-height: 100dvh;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    background: var(--main-bg-color);
    .company-name {
      color: var(--text-gray);
      font-weight: 700;
      font-size: 16px;
    }

    .links {
      display: flex;
      gap: 30px;

      a {
        color: var(--text-gray);
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 1200px) {
    .content-container {
      .content {
        width: 100vw;

        .left-container {
          height: 100dvh;
          border-radius: 0;
        }

        .right-container {
          height: 100dvh;
          border-radius: 0;
        }
      }
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    .auth-content {
      inset: 0;
      margin-top: 100px;
      position: relative;
      width: 100%;
      padding: 20px;
      margin-right: 0;
    }

    display: flex;

    .page-logo {
      width: 100%;
      left: 0;
      top: 20px;
      display: flex;
      justify-content: center;

      svg {
        width: 200px;
      }
    }

    .page-box {
      display: none;
    }

    .content-container {
      width: 90%;
      margin: 0 auto;
      min-height: auto;

      .content {
        border-radius: 0;
        background: transparent;

        .right-container {
          height: auto;
          min-height: auto;
          padding: 0;
        }
      }
    }

    footer {
      .company-name {
        font-size: 14px;
      }

      .links {
        a {
          font-size: 14px;
        }

        .about-link {
          display: none;
        }
      }
    }
  }
}
.auth-content {
  inset: 0 0 0 375px;
  position: absolute;
  margin-top: 50px;
  margin-right: 50px;
  height: calc(100dvh - 130px);
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  .auth-content {
    margin-bottom: 80px;
  }
}
.page-title-container {
  display: flex;
  flex-direction: column;
}
.page-title-image-container {
  display: flex;
  gap: 40px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.page-title {
  font-weight: 700;
  color: var(--main-blue);
  font-size: 32px;
}
.page-title-image {
  width: 150px;
  height: 80px;
  object-fit: contain;
}
.page-subtext {
  color: #514d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.dash-content {
  max-height: calc(100dvh - 240px);
  overflow-y: auto;
  &.no-scroll {
    overflow-y: hidden;
  }
  &.my-offers-content {
    max-height: initial;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .auth-content {
    .page-title {
      font-size: 26px;
    }

    .dash-content {
      &.profile-container {
        padding: 20px 40px;
        margin-top: 5px;
        max-height: unset;
        height: calc(100% - 50px);

        .profile-details {
          .form-section {
            margin-bottom: 0px;

            .form-section-header {
              .form-section-action {
                button {
                  font-size: 10px;
                  padding: 2.5px 15px;
                }
              }
            }

            .form-section-content {
              .form-section-row {
                margin-bottom: 5px;

                .field-name {
                  font-size: 14px;
                }

                .field-value {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .profile-passkeys {
          h4 {
            font-size: 16px;
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .page-title {
    font-size: 24px;
  }
  .auth-content {
    inset: 0;
    width: 100%;
    padding: 15px;
    position: fixed;
    height: calc(100dvh - 150px);
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .auth-layout {
    padding: 10px;
    width: 100%;

    footer {
      height: 40px;
      align-items: center;
      padding: 5px 10px;
      .company-name {
        font-size: 14px;
      }
      .links {
        a {
          font-size: 14px;
        }
      }
    }
  }
  .page-title-image-container {
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 30px;
    justify-content: space-between;
    img {
      width: 120px;
    }
  }
  .dash-content {
    max-height: calc(100dvh - 240px);
    margin-top: 0px;
    padding-bottom: 20px;
    overflow-y: auto;
  }
}
