.profile-container {
  background: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 75dvh;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding: 50px;
  justify-content: space-between;
  gap: 50px;
}

.profile-details {
  width: 50%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 20px;

  .guest-page {
    padding: 0 20px;
  }

  .guest-title {
    color: var(--blue-title);
    font-size: 20px !important;
    font-family: "Stage Grotesk";
    font-weight: 700;
    margin-bottom: 20px !important;
  }
}

.profile-details-section {
  margin-bottom: 50px;

  h4 {
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--main-blue);
    font-size: 24px;
  }

  button {
    border-radius: 2px;
    border: 2px solid #c3c3c3;
    width: 100px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #514d4d;
  }
}

.profile-details-section-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile-passkeys {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 50%;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;

  h4 {
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--main-blue);
    font-size: 24px;
  }

  p {
    color: #514d4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .passkeys-list {
    .enabled {
      p {
        color: #000;
      }
    }
  }

  .create-button {
    background: var(--main-blue);
    border-radius: 4px;
    width: 100%;
    color: white;
    font-weight: 700;
    margin-top: auto;
  }
}

.form-section {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .form-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--blue-title);

    .form-section-title {
      color: var(--blue-title);
      font-size: 16px;
      font-weight: 700;
    }

    .form-section-action {
      button {
        padding: 5px 20px;
        border-radius: 2px;
        border: 2px solid #0a104477;
        color: #0a1044;
        font-size: 12px !important;
        font-weight: 400;
      }
    }
  }

  .form-section-row {
    display: flex;
    margin-bottom: 10px;

    .field-name {
      color: #514d4d;
      font-size: 14px;
      font-weight: 400;
      width: 200px;
    }

    .field-value {
      color: var(--main-blue);
      font-size: 16px;
      font-weight: 700;
      width: 200px;
      overflow-wrap: anywhere;
    }
  }
}

@media screen and (max-width: 992px) {
  .profile-container {
    background: white;
    box-shadow: none;
    border-radius: 2px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    overflow-y: unset;
    gap: 0px;
    height: auto;
    max-height: unset;
  }

  .profile-details {
    width: 100%;
    padding: 20px 20px 0;
    overflow-y: unset;

    &-section {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &-title {
        display: flex;
        align-items: center;
      }

      h4 {
        font-size: 18px;
        margin-bottom: 0px;
      }

      button {
        width: 75px;
        height: 30px;
        margin-top: 0px;
      }
    }
  }

  .profile-passkeys {
    width: 100%;
    padding: 20px;
    border-radius: 0;
    height: unset;
    overflow-y: unset;
    overflow: unset;
    min-height: unset;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
  .profile-container {
    .profile-passkeys {
      .passkeys-list {
        & > div {
          gap: 20px !important;
          flex-wrap: wrap;

          & > div {
            &:first-child {
              flex: 0 0 calc(25% - 50px);
            }

            &:nth-child(2) {
              flex: 0 0 calc(75% - 50px);
            }

            &:last-child {
              flex: 0 0 100%;

              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
