@import "bootstrap/dist/css/bootstrap.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "general";

@import "layouts/auth";
@import "layouts/guest";
@import "layouts/checkout";

@import "pages/login";
@import "pages/dashboard";
@import "pages/retrieve";
@import "pages/offer-details";
@import "pages/quote-checkout";
@import "pages/register";
@import "pages/retailer-offers";

@import "components/buttons";
@import "components/inputs";
@import "components/progress-tracker";
@import "components/sidebarmenu";
@import "components/retailers";
@import "components/offers";
@import "components/applications";
@import "components/profile";
@import "components/mobilenav";
@import "components/loader";
@import "components/offer-filter";
@import "components/vehicle-information";
@import "components/quote-builder";
@import "components/full-quote-info";
@import "components/left-hand-quote-details";
@import "components/features";
@import "components/notifications";
@import "components/modal";
@import "components/offers-intro";
@import "components/pills";
@import "components/lender-card";

// Import responsive last to ensure rules are applied
@import "responsive";
