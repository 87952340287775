.guest-layout{
  .content-container {
    .content {
      .right-container {
        .guest-page {
          &.terms-page {
            overflow-y: auto;
            justify-content: flex-start;

            .terms-container {
              padding-right: 0;
            }
          }
        }

        .guest-content {
          .info-text {
            color: #0a1045;
            font-size: 20px;
            font-weight: 700;
            margin-top: 40px;
          }
        }
      }
    }
  }
}
