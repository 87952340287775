.offers-grid {
  margin-top: 20px;
  margin-right: 20px;
}
.offers-card {
  display: flex;
  background: white;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  gap: 0px;
}

.offers-vehicle {
  display: flex;
  gap: 20px;
}

.vehicle-image {
  width: 150px;
  object-fit: contain;
  img {
    border-radius: 5px;
  }
}

.offers-vehicle-details {
  display: flex;
  flex-direction: column;
  width: 250px;
  justify-content: center;
  border-right: 2px solid #f4f4f4;
}

.make-model-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.make-model {
  font-weight: 700;
  color: var(--main-blue);
  font-size: 24px;
}
.description {
  color: #818181;
  font-size: 16px;
  font-weight: 500;
}
.price {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: var(--main-blue);
  font-weight: 700;
  h4 {
    font-size: 24px;
    color: var(--main-blue);
    font-weight: 700;
  }
}
.offers-finance {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
}

.offers-finance-details {
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
  color: var(--main-blue);
  margin-top: 10px;

  font-weight: 700;
  h4 {
    color: #818181;
    font-size: 12px;
    font-weight: 500;
  }
}

.commission-info-link {
  display: block;
  color: #00A6FF;
  font-size: 12px;
  font-weight: 700;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.commission-tooltip {
  opacity: 0.9;
  width: 300px;

  .tooltip-inner {
    width: 100%;
    max-width: 100%;
  }
}

.finance-details-tooltip {
  display: flex;
  flex-direction: row;
  svg {
    width: 15px;
    margin-left: 5px;
    path {
      fill: #818181;
    }
  }
}
.offers-ppm {
  display: flex;
  width: 250px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 14px;

  color: var(--main-blue);
  h4 {
    font-size: 32px;
    color: var(--main-blue);
    font-weight: 700;
  }
}

.offers-buttons {
  display: flex;
  flex-direction: column;
  width: 250px;
  align-items: center;
}
.full-quote-button {
  font-size: 16px;
  width: 170px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #cecece;
}
.checkout-button {
  background: var(--main-blue);
  width: 170px;
  color: white;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid var(--main-blue);

  &[disabled] {
    opacity: 0.7;
  }
}
.offers-smaller-screen {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #f4f4f4;
}
@media screen and (max-width: 1523px) {
  .offers-smaller-screen {
    display: flex;
    flex-direction: column;
  }
  .offers-finance-details {
    width: calc(33.33% - 10px);
    margin-bottom: 10px;
    color: var(--main-blue);
    margin-top: 10px;
    font-weight: 700;
    h4 {
      color: #818181;
      font-size: 11px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 992px) {
  .offers-grid {
    margin-top: 10px;
    margin-right: 0;
    padding-bottom: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .offers-card {
      flex-direction: column;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      gap: 20px;
      margin-bottom: 0;
    }
  }
  .offers-vehicle {
    gap: 10px;
    align-items: center;
  }
  .offers-vehicle-details {
    width: 100%;
  }
  .vehicle-image {
    width: 100%;
    img {
      width: 160px;
      border-radius: 0px;
      height: 110px;
    }
  }
  .offers-vehicle-details {
    border: none;
  }
  .make-model-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .make-model {
    font-weight: 700;
    color: var(--main-blue);
    font-size: 18px;
  }
  .description {
    color: #818181;
    font-size: 11px;
    font-weight: 500;
  }
  .price {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    font-size: 10px;
    h4 {
      font-size: 22px;
    }
  }
  .offers-finance {
    padding: 5px;
    border: 0px;
    gap: 1px;
  }
  .offers-finance-details {
    margin-top: 5px;
    margin-bottom: 5px;
    width: calc(33.33% - 1px);
    font-size: 13px;
  }
  .offers-ppm {
    display: none;
  }
  .offers-buttons {
    width: 100%;
    .full-quote-button {
      width: 100%;
      margin-top: 0px;
    }
    .checkout-button {
      width: 100%;
    }
  }
  .offers-smaller-screen {
    width: 100%;
  }
  .offers-mobile-screen {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .offers-lender-logo {
      width: 150px;
    }
    .offers-ppm-mobile {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      font-size: 14px;
      width: 50%;
      color: #818181;
      justify-content: center;
      align-items: center;
      gap: 5px;
      h4 {
        font-size: 24px;
        color: var(--main-blue);
        font-weight: 700;
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .offers-mobile-screen {
    display: none;
  }
}
