.feature {
  position: relative;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
  .feature-icon-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .feature-title {
    color: var(--blue-title);
    font-family: "Stage Grotesk";
    font-size: 18px !important;
    font-weight: 700;
  }
  .feature-icon {
    svg {
      width: 30px;
    }
  }

  .feature-text {
    color: var(--blue-title);
    font-family: "Stage Grotesk";
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 1500px) {
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    margin-bottom: 10px;

    .feature-icon {
      svg {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .feature-text {
      display: none;
    }
  }
}
